// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

    Accordion: {
        init: function () {
            $('.accordion header').click(function () {
                $(this).siblings('.bodytext').slideToggle();
                $(this).parent().toggleClass('open');
            });
            var preOpen = $('.accordion').first();
            $(preOpen).find('.ce-inner').addClass('open');
            $(preOpen).find('.bodytext').show();
        }
    },

	MobileMenu: {
		init: function() {
            $('.header-top .inner').prepend('<div class="burger-icon"><div></div><div></div><div></div></div>');
            FE.MobileMenu.indicateSubMenu();
			$('.burger-icon').click(function() {
                $('.nav-sub').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-sub > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                    $(this).parents('li').toggleClass('open');
                });
            });
        }
	},

    ImageSlider: {
        init: function() {
            $('.job-images').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: false,
                infinite: true,
                speed: 1000,
                fade: false,
                slide: 'img',
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 5000,
                centerMode: false,
                pauseOnHover: false,
                focusOnSelect: false
            });
        }
    },

    JobFilter: {

	    country: 0,
        location: 0,
        level: 0,
        area: 0,

	    init: function () {
            FE.JobFilter.setupCountries();
            $('.openjobs-filter option').append('<span></span>');
            $('#filter-countries').on('change', function () {
                FE.JobFilter.country = $(this).find(':selected').val();
                FE.JobFilter.applyAllFilters();
                FE.JobFilter.hideUnselectedFilters($(this));
            });
            $('#filter-locations').on('change', function () {
                FE.JobFilter.filterByLocation($(this).find(':selected').val());
                FE.JobFilter.hideUnselectedFilters($(this));
            });
            $('#filter-levels').on('change', function () {
                FE.JobFilter.level = $(this).find(':selected').val();
                FE.JobFilter.applyAllFilters();
                FE.JobFilter.hideUnselectedFilters($(this));
            });
            $('#filter-areas').on('change', function () {
                FE.JobFilter.area = $(this).find(':selected').val();
                FE.JobFilter.applyAllFilters();
                FE.JobFilter.hideUnselectedFilters($(this));
            });
            $('.openjobs-filter button.reset').on('click', function () {
                $('.openjobs-filter option')
                    .prop("selected", false)
                    .show();
                $('.open-jobs-list li').show();
                FE.JobFilter.country = 0;
                FE.JobFilter.location = 0;
                FE.JobFilter.level = 0;
                FE.JobFilter.area = 0;
                FE.JobFilter.updateCount();
            });
            FE.JobFilter.updateCount();
        },
        setupCountries: function () {
            var countries = [];
            $('.openjobs-filter #filter-locations option[data-country]').each(function () {
                var curCountryId = parseInt($(this).data('countryId'));
                countries[curCountryId] = $(this).data('country');
            });
            for (var i in countries) {
                //console.log('key is: ' + i + ', value is: ' + countries[i]);
                $('#filter-countries').append('<option value="' + i +'">' + countries[i] + '</option>');
            }
            //$('#filter-countries').on('change', function () {
                //FE.JobFilter.filterByCountry( $(this).find(':selected').val() );
            //})

        },
        syncCountry: function (countryId) {
            FE.JobFilter.country = parseInt(countryId);
            $('#filter-countries option').prop("selected", false);
            $('#filter-countries option[value="' + countryId + '"]').prop("selected", true);
        },
        filterByCountry: function ( countryId ) {
            console.log( countryId );
            FE.JobFilter.country = countryId;
            //$('.open-jobs-list li').not('.openjobs-filter-country-' + countryId).hide();
            $('.open-jobs-list li').hide();
            $('.open-jobs-list li.openjobs-filter-country-' + countryId).show();
            // adjust location filter
            $('#filter-locations option[data-country]').hide();
            $('#filter-locations .filter-countries-' + countryId).show();
        },
        filterByLocation: function (locationId) {
            var countryId = 0;
            $('#filter-locations option').prop("selected", false);

            if ( locationId == '' ) {
                locationId = 0;
                FE.JobFilter.country = 0;
            } else {
                var selectedOption = $('#filter-locations option[value="' + locationId + '"]');
                selectedOption.prop("selected", true);
                countryId = selectedOption.data('countryId');
            }
            FE.JobFilter.location = parseInt(locationId);
            FE.JobFilter.applyAllFilters();

            //console.log(countryId);
            if ( typeof (countryId) !== 'undefined' ) {
                FE.JobFilter.syncCountry( countryId );
            }
        },
        applyAllFilters: function () {
            $('.open-jobs-list li').show();
            if ( FE.JobFilter.location != '' ) {
                $('.open-jobs-list li:visible').not('.openjobs-filter-location-' + FE.JobFilter.location).hide();
            } else {
                if ( FE.JobFilter.country != '' ) {
                    $('.open-jobs-list li:visible').not('.openjobs-filter-country-' + FE.JobFilter.country).hide();
                }
            }
            if ( FE.JobFilter.level != '' ) {
                $('.open-jobs-list li:visible').not('.openjobs-filter-level-' + FE.JobFilter.level).hide();
            }
            if ( FE.JobFilter.area != '' ) {
                $('.open-jobs-list li:visible').not('.openjobs-filter-area-' + FE.JobFilter.area).hide();
            }
            FE.JobFilter.updateCount();
        },
        updateCount: function () {
            var $curList = $('.open-jobs-list');
            $('#filter-countries option').not(':eq(0)').each(function () {
                var hits = $curList.find('li:visible.openjobs-filter-country-' + $(this).val()).length;
                $(this).find('span').text(' (' + hits + ')');
            });
            $('#filter-locations option').not(':eq(0)').each(function () {
                var hits = $curList.find('li:visible.openjobs-filter-location-' + $(this).val()).length;
                $(this).find('span').text(' (' + hits + ')');
            });
            $('#filter-levels option').not(':eq(0)').each(function () {
                var hits = $curList.find('li:visible.openjobs-filter-level-' + $(this).val()).length;
                $(this).find('span').text(' (' + hits + ')');
            });
            $('#filter-areas option').not(':eq(0)').each(function () {
                var hits = $curList.find('li:visible.openjobs-filter-area-' + $(this).val()).length;
                $(this).find('span').text(' (' + hits + ')');
            });
        },
        hideUnselectedFilters: function (select) {
            var options = $(select).find('option');
            // only apply if not first (defaul / reset) option has been selected
            if ( $(select).find(':selected').index() > 0 ) {
                $(options).hide();
                $(options).first().show();
                $(select).find(':selected').show();
            } else {
                $(options).show();
            }
        }
    },

    GoogleMaps: {

        map: null,
        markers: [],
        markerPin: null,

        init: function () {
            var mapOptions = {
                zoom: 5,
                center: new google.maps.LatLng(48.515926, 9.756814)
            };

            FE.GoogleMaps.map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

            $('#filter-locations option[data-geo-lat]').each(function () {
                FE.GoogleMaps.addMarker( $(this).text(), $(this).data('geoLat'), $(this).data('geoLong'), $(this).val() );
            });
            /*
            var markerCluster = new MarkerClusterer(FE.GoogleMaps.map, FE.GoogleMaps.markers,
                {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
             */
        },
        addMarker: function (title, lat, long, locationId) {
            //console.log( title, lat, long, locationId );
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng( lat, long ),
                locationId: locationId,
                map: FE.GoogleMaps.map,
                //icon: decodeURIComponent(data.icon),
                title: title
                //category: data.category
            });

            google.maps.event.addListener(marker, 'click', function() {
                FE.JobFilter.filterByLocation(locationId);
                //console.log(locationId);
            });

            FE.GoogleMaps.markers.push(marker);
        }

    },

    ApplicationForm: {
        init: function () {
            $('.powermail_fieldset_1 input').attr('readonly', 'readonly');

            $('.page-id-22 #powermail_field_jobposition').val('unsolicited application');
            $('.page-id-22 #powermail_field_jobreference').val('0000');

            FE.ApplicationForm.monitorPresets();

            FE.ApplicationForm.showCommentCharLimit();

            if ( $('.powermail_form_1').length ) {
                FE.ApplicationForm.showLoadingIcon();
            }
        },
        monitorPresets: function () {
            if ( $('.page-id-29 #powermail_field_jobposition').val() == '' )  {
                $('.page-id-29 #powermail_field_jobposition').val('no job title');
            }
            if ( $('.page-id-29 #powermail_field_jobreference').val() == '' )  {
                $('.page-id-29 #powermail_field_jobreference').val('no job reference');
            }
        },
        showCommentCharLimit: function () {
            var charInfoText = 'characters remaining';
            if ( $('html').attr('lang') === 'de' ) {
                charInfoText = 'Zeichen verfügbar';
            }
            var $textarea = $('#powermail_field_othercomments');

            var maxLength = JSON.parse( document.getElementById('powermail_field_othercomments').dataset.powermailLength)[1];
            //var maxLength = $textarea.data('parsleyLength')[1];
            $textarea.attr('maxlength', maxLength);
            $textarea.after('<div class="textarea-chars-remaining-note"><span id="textarea-chars-remaining">' + 500 +  '</span> ' + charInfoText + '.</div>');
            $textarea.on('keyup', function() {
                var remainingChars = maxLength - $(this).val().length;
                $('#textarea-chars-remaining').text(remainingChars);
            });
        },
        showLoadingIcon: function () {

            $('body').append('<div class="application-sending-overlay"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg></div>');

            $('.powermail_form_1').on('submit', function () {
                $('.application-sending-overlay').addClass('active');

                // check for JS Validation errors of parsley
                setTimeout(function () {
                    if ( $('.parsley-error').length ) {
                        $('.application-sending-overlay').removeClass('active');
                    }
                }, 1000);
            });
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();
        if ( $('.accordion').length ) {
            FE.Accordion.init();
        }
        if ( $('.openjobs-filter').length ) {
            FE.JobFilter.init();
            FE.GoogleMaps.init();
        }
        if ( $('.powermail_form_1').length ) {
            FE.ApplicationForm.init();
        }

        if ( $('.job-images img').length > 1 ) {
            FE.ImageSlider.init();
        }

	});

}(jQuery, window, document));
